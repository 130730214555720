import React, { useState } from 'react'; //{ useEffect, useState, useContext, useRef }

// const delimiters = [KeyCodes.comma, KeyCodes.enter];
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RangePicker from './RangePicker';
// import { programmingEndpoint } from '../../services/endpoints';
import moment from 'moment';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';
// import { programmingEndpoint } from '../../services/endpoints';
// import { API_URL_NODE_DEV } from '../../config/envVariables';
import { programmingEndpoint } from '../../services/endpoints';

export const Reports = () => {
  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Movimiento', width: 220 },

    { field: 'full', headerName: 'Full body', width: 100 },
    { field: 'lower', headerName: 'Lower body', width: 100 },
    { field: 'upper', headerName: 'Upper body', width: 100 },
    { field: 'cardio', headerName: 'Cardio', width: 80 },
    { field: 'fba', headerName: 'FBA', width: 80 },
    { field: 'Sport', headerName: 'Sport', width: 80 },

    { field: 'strength', headerName: 'Strength', width: 80 },
    { field: 'hiit', headerName: 'HIIT', width: 80 },
    { field: 'THYPERTROPHY', headerName: 'T.HYPERTROPHY', width: 100 },
    { field: 'FBHYPERTROPHY', headerName: 'FBHYPERTROPHY', width: 100 },
    { field: 'COMBINED', headerName: 'COMBINED STRENGTH', width: 100 },

    
    { field: 'BIGGinners', headerName: 'BIGGinners', width: 100 },
    { field: 'midline', headerName: 'Midline', width: 80 },
    { field: 'total', headerName: 'Total', width: 100 },
  ];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [rows, setRows] = useState([]);
  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    console.log('dates', dates);
    setStartDate(start);
    setEndDate(end);
  };
  const [loading, setloading] = useState(false);
  const [division, setdivision] = useState(55);
  const handleSubmit = async () => {
    setloading(true);

    try {
      const response = await fetch(
        // API_URL_NODE_DEV +
        programmingEndpoint +
          `/reports/movements?fromDate=${moment(startDate).format('YYYY-MM-DD')}&toDate=${moment(
            endDate,
          ).format('YYYY-MM-DD')}&division=${division}`,
      );
      const parsed = await response.json();
      console.log('parsed',parsed);
      
      if (parsed) {
        setRows(
          parsed
            .sort(
              (
                a: { key: any; titleMovement: any; values: any },
                b: { key: any; titleMovement: any; values: any },
              ) => (a.values.length > b.values.length ? -1 : 1),
            )
            .map((responseItem: { key: any; titleMovement: any; values: any }) => ({
              id: responseItem.key,
              col1: responseItem.titleMovement,
              full: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Full Body',
              ).length,
              lower: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Lower Body',
              ).length,
              upper: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Upper Body',
              ).length,
              cardio: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Cardio',
              ).length,
              fba: responseItem.values.filter(
                (value: { training: string }) => value.training == 'FBA',
              ).length,
              strength: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Strength',
              ).length,
              hiit: responseItem.values.filter(
                (value: { training: string }) => value.training == 'HIIT',
              ).length,
              THYPERTROPHY: responseItem.values.filter(
                (value: { training: string }) => value.training == 'TRADITIONAL HYPERTROPHY',
              ).length,
              FBHYPERTROPHY: responseItem.values.filter(
                (value: { training: string }) => value.training == 'FULL BODY HYPERTROPHY',
              ).length,
              COMBINED: responseItem.values.filter(
                (value: { training: string }) => value.training == 'COMBINED STRENGTH',
              ).length,
              
              BIGGinners: responseItem.values.filter(
                (value: { training: string }) => value.training == 'BIGGinners',
              ).length,

              midline: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Midline',
              ).length,
             
              Sport: responseItem.values.filter(
                (value: { training: string }) => value.training == 'Sport',
              ).length,
              total: responseItem.values.length,
            })),
        );
        setloading(false);
        // setlistReport(parsed);
      }
      console.log('parsed', parsed);
    } catch (error) {
      console.log('err', error);
    }
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}
        >
          {/* <p>División</p> */}
          <select onChange={(e) => setdivision(parseInt(e.target.value))}>
            <option value={55}>Equipped</option>
            <option value={158}>Home</option>
            <option value={190}>Outdoor</option>
          </select>
        </div>

        <div style={{ marginRight: 20 }}>
          {/* <p>Fecha desde</p> */}
          <RangePicker startDate={startDate} endDate={endDate} onChange={onChange} />
        </div>
        <div
          onClick={() => {
            if (!endDate || !startDate) {
              alert('Falta fecha de inicio o fecha de fin para poder filtrar.');
              return;
            }
            if (!loading) {
              handleSubmit();
            }
          }}
          style={{
            background: '#ccc',
            borderRadius: 5,
            padding: '5px 20px',
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: 35,
            marginLeft: 10,

            cursor: 'pointer',
          }}
        >
          <p>FILTRAR</p>
          {loading && <Spinner style={{ marginLeft: 10 }} />}
        </div>
      </div>
      <div style={{ width: '100%', height: 1200 }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
    </div>
  );
};

export default Reports;
